import * as React from "react"
import Layout from "../components/Layout/layout"
import Seo from "../components/Layout/seo"
import Navbar from "../components/Consulting/Navbar"
import LatestArticles from "../components/Consulting/LatestArticles"
import MainBanner from "../components/Consulting/MainBanner"
import Tables from "../components/Tables/Tables"
import Features from "../components/Consulting/Features"
// import AboutUs from "../components/Consulting/AboutUs"
import PriceTable from "../components/Consulting/PriceTable"
import WhyChooseUs from "../components/Consulting/WhyChooseUs"
import RecentProjects from "../components/Consulting/RecentProjects"
// import OurTeam from "../components/Consulting/OurTeam"
import Testimonials from "../components/Consulting/Testimonials"
import Footer from "../components/Consulting/Footer"

const Consulting = () => {
  return (
    <Layout>

      <Seo title="Consulting" />

      <Navbar />

      <MainBanner />

      <Testimonials />

      {/* <Partner /> */}

      {/* <AboutUs /> */}

      <WhyChooseUs />

      {/* <Services /> */}

      <Features />

      <RecentProjects />

      <LatestArticles />

   

       <PriceTable />

      <Tables />

      {/* <OurTeam /> */}


      {/* <Newsletter /> */}

     

      {/* <ContactForm /> */}

      <Footer />

    </Layout>
  )
}

export default Consulting
