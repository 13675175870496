import * as React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Pagination, Autoplay } from "swiper"
import { useStaticQuery, graphql } from 'gatsby'
import ReactStarsRating from 'react-awesome-stars-rating';

import shapeBgImg from "../../images/reviews/reviews-shape-1.png"


import shape1 from "../../images/about/about-shape-2.png"

const Testimonials = () => {
  const {
    allGoogleTestisSheet: { nodes }
  } = useStaticQuery(graphql`
  query{
    allGoogleTestisSheet {
      nodes {
        id
        metode
        nama
        paket
        rating
        testimoniText
      }
    }
  }
`);

  return (
        <> 
      <div 
        id="testimonials"
        className="consulting-testimonial-area pt-50 pb-60"  
        style={{ backgroundImage: `url(${shapeBgImg})` }}
      >
        {/* <div className="container"> */}
          {/* <div className="consulting-section-title">
          <span>Testimonials</span>
            <h2>Semua Klien Kami Senang</h2>
          </div> */}
          
          <Swiper
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 4,
              },
              992: {
                slidesPerView: 6,
              },
            }}
            autoplay={{
              delay: 3000,
              pauseOnMouseEnter: true,
            }}
            modules={[Pagination, Autoplay]}
            className="consulting-testimonial-slider"
          >

        
            {nodes.map((node) => (
                  <SwiperSlide  key={node.id}>
                      <div className="app-reviews-box">
                        <div className="rating-star">
                        
                          <p>{node.testimoniText}</p>
                          <ReactStarsRating 
                          value={node.rating}
                          primaryColor={'orange'}
                          isEdit={false}/>
                        </div>
                        <div className="cliens-profile">
                          <h6>{node.nama}</h6>
                          <p>{node.metode} - {node.paket}</p>
                        </div>
                      </div>
                        
                    </SwiperSlide>))}
              

          </Swiper>

          {/* <img src={shape1} className="testimonial-shape-1" alt="jasa olah data" /> */}
        {/* </div> */}
      </div>
    </>
  )
}

export default Testimonials
