import styled from 'styled-components'

export const Styles = styled.div`
  .table {
    border: 1px solid #ddd;
    

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }
    

    .th {
        padding: 5px;
        border-bottom: 1px solid #ddd;
        border-right: 1px solid #ddd;
        background-color: #6064e3;
        color: white;
        overflow: hidden;
  
        :last-child {
          border-right: 0;
        }
      }
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
        width: 5px;
        
      }

      
    }
  }
`
