

export const COLUMNS = [
  {
    Header: 'ID',
    Footer: 'iD',
    accessor: 'iD',
    disableFilters: true,
    sticky: 'left'
  },
  {
    Header: 'Tanggal',
    Footer: 'Tanggal',
    accessor: 'tanggal',
    sticky: 'left'
  },
  {
    Header: 'Analisis',
    Footer: 'analisis',
    accessor: 'analisis',
    sticky: 'left'
  },
  {
    Header: 'Paket',
    Footer: 'paket',
    accessor: 'paket',
    sticky: 'left'
  },
  {
    Header: 'Analyst',
    Footer: 'analyst',
    accessor: 'analyst',
    sticky: 'left'
  },
  {
    Header: 'Status',
    Footer: 'status',
    accessor: 'status'
  }
]