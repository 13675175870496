import * as React from "react"
import { Tabs, TabPanel } from "react-tabs"

import { Link } from "gatsby"

const PriceTable = () => {

  if (typeof document !== 'undefined') {
    var source = new URL(document.location.href).searchParams.get('utm_source');
    var mesg_text1 = false;
    var mesg_text2 = false;
    var mesg_text3 = false;
      
    switch(source) {
        case 'gads':
          mesg_text1 = "https://wa.me/6285218289639?text=Halo ReStat,Saya Pengen Paket Basic";
          mesg_text2 = "https://wa.me/6285218289639?text=Halo ReStat,Saya Pengen Paket Standard";
          mesg_text3 = "https://wa.me/6285218289639?text=Halo ReStat,Saya Pengen Paket Premium";
        break;
        
        default:
        break;
    }
    
    var msg1=mesg_text1;
    var msg2=mesg_text2;
    var msg3=mesg_text3;
    }
    if(mesg_text1) {msg1=msg1}
    else {msg1='https://wa.me/6285218289639?text=Halo ReStat, Aku Pengen Paket Basic'}
    
    if(mesg_text2) {msg2=msg2}
    else {msg2='https://wa.me/6285218289639?text=Halo ReStat, Aku Pengen Paket Standard'}
    
    if(mesg_text3) {msg3=msg3}
    else {msg3='https://wa.me/6285218289639?text=Halo ReStat, Aku Pengen Paket Premium'}

  return (
    <>
      <div id="pricing" className="pricing-area bg-color-f8f8f8 pt-100 pb-70">
        <div className="container">
          <div className="sass-section-title">
            <span>Price List</span>
            <h2>Jasa Olah Data Kami Sangat Terjangkau</h2>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="tab">
                <Tabs>

                  <TabPanel>
                    <div className="row justify-content-center">
                      <div
                        className="col-lg-4 col-sm-4"
                      >
                        <div className="single-pricing-box">
                          <div className="pricing-content">
                            <h3>Basic</h3>
                            <h1>Mulai Rp 250.000</h1>
                            {/* <p>Per Month, Billed Monthly</p> */}

                            <div className="pricing-list">
                              <ul>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Olah Data
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Interpretasi Hasil
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Gratis Konsultasi
                                </li>
                              </ul>
                            </div>

                            <Link to={msg1} target='_blank' className="pricing-btn">
                              Get Started
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-sm-4"
                      >
                        <div className="single-pricing-box">
                          <div className="pricing-content">
                            <h3>Standard</h3>
                            <h1>Mulai Rp. 500.000</h1>

                            <div className="pricing-list">
                              <ul>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Olah Data
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Interpretasi Hasil
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Gratis Konsultasi
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  <strong>Gratis Revisi</strong>
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  <strong>Analisis Deskriptif</strong>
                                </li>
                              </ul>
                            </div>

                            <Link to={msg2} target='_blank' className="pricing-btn">
                              Get Started
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-lg-4 col-sm-4"
                      >
                        <div className="single-pricing-box active">
                          <div className="pricing-content">
                            <h3>Premium</h3>
                            <h1>Mulai Rp. 700.000</h1>

                            <div className="pricing-list">
                              <ul>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Olah Data
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Interpretasi Hasil
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  Gratis Konsultasi
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  <strong>Gratis Revisi</strong>
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  <strong>Analisis Deskriptif</strong>
                                </li>
                                <li>
                                  <i className="flaticon-check"></i> 
                                  <strong>Bimbingan Sampai Lulus</strong>
                                </li>
                                <li>
                                  <i className="flaticon-check"></i>
                                  <strong>Interpretasi Menyerupai Bab 4 & 5</strong>
                                </li>
                              </ul>
                            </div>

                            <Link to={msg3} target='_blank' className="pricing-btn">
                              Get Started
                            </Link>
                          </div>
                          <span className="popular">Best Deal</span>
                        </div>
                      </div>
                      
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>

          {/* <img src={shape7} className="pricing-shape" alt="jasa olah data" /> */}
        </div>
      </div>
    </>
  )
}

export default PriceTable
