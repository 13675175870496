import React, { useMemo } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useTable, useBlockLayout } from 'react-table'
import { useSticky } from 'react-table-sticky'
import { Styles } from './TableStyles'
import { MDBTable } from 'mdb-react-ui-kit';
import { COLUMNS } from "./Column"
import shapeBgImg from "../../images/reviews/reviews-shape-1.png"


export const BasicTable = () => {
    const {
      allGoogleOrderSheet: { nodes }
    } = useStaticQuery(graphql`
    query{
      allGoogleOrderSheet(sort: {order: DESC, fields: iD}) {
        nodes {
          iD
          paket
          tanggal
          status
          analyst
          analisis
        }
      }
    }
  `);
    
    const columns = COLUMNS
    const data = nodes
  
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      rows,
      prepareRow
    } = useTable(
      {
        columns,
        data
      },
      useBlockLayout,
      useSticky
    )
  
    const firstPageRows = rows.slice(0, 15)
  
  
    return (
        <>
            <div 
        id="project"
        className="consulting-testimonial-area pt-100 pb-100"  
        style={{ backgroundImage: `url(${shapeBgImg})` }}
      >
        <div className="container">
          <div className="consulting-section-title">
          <span>Progress Project</span>
            <MDBTable responsive>
            <Styles>
                    
                    <div {...getTableProps()} className="table sticky" style={{ width:950, height: 450 }}>
                    <div className="header">
                        {headerGroups.map((headerGroup) => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map((column) => (
                            <div {...column.getHeaderProps()} className="th">
                                {column.render('Header')}
                            </div>
                            ))}
                        </div>
                        ))}
                    </div>
                    <div {...getTableBodyProps()} className="body">
                        {firstPageRows.map((row) => {
                        prepareRow(row);
                        return (
                            <div {...row.getRowProps()} className="tr">
                            {row.cells.map((cell) => (
                                <div {...cell.getCellProps()} className="td">
                                {cell.render('Cell')}
                                </div>
                            ))}
                            </div>
                        );
                        })}
                    </div>
                    </div>
                </Styles>
                </MDBTable>
          </div>
        </div>
      </div>
       
        </>
     

    )
  }
  
export default BasicTable




  
