import * as React from "react"
import { Link } from "gatsby"


if (typeof document !== 'undefined') {
  var source = new URL(document.location.href).searchParams.get('utm_source');
  var mesg_text = false;
    
  switch(source) {
      case 'gads':
        mesg_text = "https://wa.me/6285218289639?text=Halo ReStat, Saya mau konsultasi";
      break;
      
      default:
      break;
  }
  
  var msg=mesg_text;
  }
  if(mesg_text) {msg=msg}
  else {msg='https://wa.me/6285218289639?text=Halo ReStat, Aku mau konsultasi'}

const RecentProjects = () => {
  return (
    <>
      <div id="portfolio" className="consult-project-area bg-color-f8f8f8 pt-100 pb-70">
      <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            </div>
              <div className="sass-data-content">
                <div className="consulting-section-title">
                  <span>Portfolio</span>
                  <h2>Berikut Hasil Pengerjaan Kami</h2>
                </div>

                <div className="data-list"
                  >
                  <ul>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    > <div>
                      <div className="icon" >
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/11D-RBnKBmrq-zwjlHdTSyCT1q-sp-cwY/preview" className="download-btn" target="_blank">
                        Korelasi{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                      </div>
                    </li>
                   

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1txkgzPMhSKzLXvp94adsYFc6ElC4e3vC/preview" className="download-btn" target="_blank">
                      Chi-Square{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                      
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1uqTRAfoI6dTyp0m77LN3jCOvx3dEEzVs/preview" className="download-btn" target="_blank">
                      Validitas & Reliabilitas{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/13ngL-IXG8v4a0Y5EAJfdhpVxja6M-7Lq/preview" className="download-btn" target="_blank">
                      Anova/Ancova{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1BfFgW1vL5b_4ATefzOMN3cQ-xMfT3MVt/preview" className="download-btn" target="_blank">
                      T-Test{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1nMGdYCvyBZONuu121pxAFdxqqv82zVIH/preview" className="download-btn" target="_blank">
                      Regresi Berganda{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/13sfvA26SObCJvesd-DaQdvwGoGXAE-hn/preview" className="download-btn" target="_blank"> 
                      Regresi Logistik{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1hIuDfJkUdFSQrKbglRBPBb1cYasNfN0l/preview" className="download-btn" target="_blank">
                      Regresi Data Panel{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1LyawBbLrSnAVJJf7zknW34U3JeqBrtG7/preview" className="download-btn" target="_blank">
                      Path Analisis{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1QOlLLfBGj3YLnP0VuJID8XjFD1rrQgFo/preview" className="download-btn" target="_blank">
                      Time Series{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/1YxbkfrA-hDkP22NSDjw21cG9ArWQtPot/preview" className="download-btn" target="_blank">
                      SEM{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>

                    <li
                      data-aos="fade-up"
                      data-aos-duration="1200"
                      data-aos-delay="100" 
                      data-aos-once="true"
                    >
                      <div className="icon">
                        <i className="flaticon-book-1"></i>
                      </div>
                      <Link to="https://drive.google.com/file/d/19NhlHiZOsVjKin8L7zBRN0Dr6OrILI9F/preview" className="download-btn" target="_blank">
                      SEM PLS{" "}
                        <i className="flaticon-downloading"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
          </div>
          <Link to={msg} className="consulting-default-btn btn" target="_blank">
                Selesaikan Analisa Sekarang <i className="flaticon-right-arrow-3"></i>
          </Link>
            
        </div>
      </div>
    </>
  )
}

export default RecentProjects
