import * as React from "react"
import { Link } from "gatsby"

import blogImg1 from "../../images/blog/blog-1.webp"
import blogImg2 from "../../images/blog/blog-2.webp"
import blogImg3 from "../../images/blog/blog-11.webp"

const LatestArticles = () => {
  return (
    <>
      <div id="blog" className="consultig-blog-area pt-100 pb-70">
        <div className="container">
          <div className="consulting-section-title">
            <span>Statistic Articles</span>
            <h2>Artikel dan Tutorial Statistik</h2>
          </div>
          <div className="row justify-content-center">
            <div
              className="col-lg-4 col-md-6"
            >
              <div className="consulting-blog-card">
                <div className="blog-img">
                    <img src={blogImg1} alt="Image" />
                </div>
                <div className="blog-content">
                  <ul className="list">
                    <li>
                      <i className="flaticon-user"></i> by{" "}
                      <a href="https://blog.restatolahdata.id/author/admin-restat/" target='_blank' >Admin ReStat</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar"></i> March 23, 2023
                    </li>
                  </ul>
                  <h3>
                    <Link to="https://blog.restatolahdata.id/cara-mengolah-data-kuesioner-smartpls/" target='_blank'>
                    Cara Mengolah Data Kuesioner dengan SmartPLS (SEM PLS)
                    </Link>
                  </h3>
                  <Link to="https://blog.restatolahdata.id/cara-mengolah-data-kuesioner-smartpls/"  target='_blank' className="read-more-2">
                    Read More <i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
            >
              <div className="consulting-blog-card">
                <div className="blog-img">
                    <img src={blogImg2} alt="Image" />
                </div>
                <div className="blog-content">
                  <ul className="list">
                    <li>
                      <i className="flaticon-user"></i> by{" "}
                      <a href="https://blog.restatolahdata.id/author/kadek-aris/" target='_blank'>Kadek Aris</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar"></i> March 23, 2023
                    </li>
                  </ul>
                  <h3>
                    <Link to="https://blog.restatolahdata.id/cara-uji-one-way-anova-di-spss/" target='_blank'>
                    Cara Uji One Way Anova di SPSS
                    </Link>
                  </h3>
                  <Link to="https://blog.restatolahdata.id/cara-uji-one-way-anova-di-spss/" target='_blank' className="read-more-2">
                    Read More <i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div
              className="col-lg-4 col-md-6"
            >
              <div className="consulting-blog-card">
                <div className="blog-img">
                    <img src={blogImg3} alt="Image" />
                </div>
                <div className="blog-content">
                  <ul className="list">
                    <li>
                      <i className="flaticon-user"></i> by{" "}
                      <a href="https://blog.restatolahdata.id/author/rahmat-putra/" target='_blank'>Rahmat Putra</a>
                    </li>
                    <li>
                      <i className="flaticon-calendar"></i> March 23, 2023
                    </li>
                  </ul>
                  <h3>
                    <Link to="https://blog.restatolahdata.id/analisa-regresi-logistik-dengan-spss/" target='_blank'>
                    Cara Uji Regresi Logistik dengan SPSS
                    </Link>
                  </h3>
                  <Link to="https://blog.restatolahdata.id/analisa-regresi-logistik-dengan-spss/" target='_blank' className="read-more-2">
                    Read More <i className="flaticon-right-arrow-1"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LatestArticles
