import * as React from "react"
import { Link,useStaticQuery, graphql } from "gatsby"

import bannerMainImg from "../../images/banner/banner-top.webp"
import bannerBGImg from "../../images/banner/banner-bg-3.webp"
// import shape30 from "../../images/shape/shape-30.png"
// import shape31 from "../../images/shape/shape-31.png"

const MainBanner = () => {
  const {
    allGoogleMaxSheet: { nodes }
  } = useStaticQuery(graphql`
  query{
    allGoogleMaxSheet {
      nodes {
        id
        maxID
      }
    }
  }
`);

const MAX = 15;
const MIN = 5;
var date = Date;
var seedrandom = require('seedrandom');
seedrandom(date, { global: true });
const initialRandom = Math.random();
const multiplied = initialRandom * MAX + MIN;
const answer = Math.floor(multiplied);

if (typeof document !== 'undefined') {
var source = new URL(document.location.href).searchParams.get('utm_source');
var mesg_text = false;
  
switch(source) {
    case 'gads':
      mesg_text = "https://wa.me/6285218289639?text=Halo ReStat, Saya mau konsultasi";
    break;
    
    default:
    break;
}

var msg=mesg_text;
}
if(mesg_text) {msg=msg}
else {msg='https://wa.me/6285218289639?text=Halo ReStat, Aku mau konsultasi'}

// if (typeof document !== 'undefined') {
// var city = new URL(document.location.href).searchParams.get('city');
// var keyword = new URL(document.location.href).searchParams.get('keyword');
// var city_name = false;
  
// switch(city) {
//     case '9072594':
//         city_name = "Jakarta";
//     break;

//     case '9072596':
//         city_name = "Jakarta";
//     break;

//     case '9072592':
//         city_name = "Jakarta";
//     break;

//     case '1007715':
//         city_name = "Surabaya";
//     break;

//     case '9072593':
//         city_name = "Jakarta";
//     break;

//     case '1007700':
//         city_name = "Bandung";
//     break;

//     case '1007719':
//         city_name = "Semarang";
//     break;

//     case '1007737':
//         city_name = "Medan";
//     break;

//     case '1007736':
//         city_name = "Palembang";
//     break;

//     case '9072595':
//         city_name = "Jakarta";
//     break;

//     default:
//     break;
// }

// var k1 = "ReStat, ";
// var k2 = keyword;
// var k3 = k2+" ";
// var s1 = k3.concat(city_name);

// function capitalizeTheFirstLetterOfEachWord(words) {
//    var separateWord = words.toLowerCase().split(' ');
//    for (var i = 0; i < separateWord.length; i++) {
//       separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
//       separateWord[i].substring(1);
//    }
//    return separateWord.join(' ');
// }

// var s2=capitalizeTheFirstLetterOfEachWord(s1);
// var result=k1.concat(s2);
// }
// if(city_name) {result=result}
// else {result='ReStat, Tutor Statistik Anda!'}

  return (
    <>
      <div 
        id="home" 
        className="consulting-banner-area" 
        style={{ backgroundImage: `url(${bannerBGImg})` }}
      >
        {nodes.map((node) => (
        <div className="container-fluid" key={node.id}>
          <div className="row align-items-center">
            <div
              className="col-lg-6"
            >
              
              <div className="consulting-banner-content">
                <span>Statistic Consultant</span>


                <h1> ReStat, Tutor Statistik Anda! </h1>
                
              

                <h4>
                Dipercaya oleh {node.maxID} client.
                </h4>

                <Link to={msg} target='_blank' className="consulting-default-btn btn mr-20">
                  Gratis Konsultasi <i className="flaticon-right-arrow-3"></i>
                </Link>

                <p>Contacted {answer} times today.</p>
              </div>
            </div>

            <div className="col-lg-6">
              <div
                className="consulting-banner-image"
              >
                <img src={bannerMainImg} alt="jasa olah data" />

                {/* <div className="month-stars">
                  <ul className="list1 d-flex justify-content-between">
                    <li>Client Terbantu</li>
                    <li>Stars</li>
                  </ul>

                  <ul className="list2 d-flex justify-content-between">
                  <li>{node.maxID}</li>
                    <li>1000</li>
                  </ul>

                  <div className="progress">
                    <div
                      className="progress-bar"
                      role="progressbar"
                      style={{ width: '82%' }}
                      aria-valuenow="10"
                      aria-valuemin="0"
                      aria-valuemax="25"
                    ></div>
                  </div>
                </div>

                <div className="careear">
                  <div className="content">
                    <div className="icon">
                      <i className="flaticon-idea"></i>
                    </div>
                    <p>Expand Your Career Opportunities with Our Coaching</p>
                  </div>
                </div>
                <img
                  src={shape30}
                  className="banner-shape-1"
                  alt="jasa olah data"
                />
                <img
                  src={shape31}
                  className="banner-shape-2"
                  alt="jasa olah data"
                /> */}
              </div>
            </div>
          </div>
        </div>
        ))}
      </div>
    </>
  )
}

export default MainBanner
